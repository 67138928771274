import React from "react";
import _ from "lodash";
import { Link } from "gatsby";
import { styled } from "@mui/material/styles";

const TagContainer = styled("div")(`
    & a {
        text-decoration: none;
        display: inline-grid;
    }

    & span {
        font-size: 0.8rem;
        font-weight: 500;
        padding: 0.3rem .6rem;
        margin: 0.3rem;
        border-radius: 3px;
        background: rgba(141, 154, 169, 0.21);
        color: rgba(0, 0, 0, 0.54);
    }
`);

const PostTags = ({ tags }) => {
  return (
    <TagContainer>
      {tags &&
        tags.map((tag) => (
          <Link key={tag} to={`/tags/${_.kebabCase(tag)}`}>
            <span>{tag}</span>
          </Link>
        ))}
    </TagContainer>
  );
};

export default PostTags;
