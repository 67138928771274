import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import PostTags from "../components/PostTags";
import Seo from "../components/Seo";
import config from "../../data/SiteConfig";
import { styled } from "@mui/material/styles";

const PostMeta = styled("div")(`
    color: #757575;
    margin-bottom: 0.5rem;
    font-size: 0.7rem;
`);

const Pagination = styled("ul")(`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin-top: 4rem;
`);

const PostContent = styled("div")(`
    & .gatsby-resp-image-image {
      object-fit: contain !important;
    }
`);

const Post = ({ data, pageContext }) => {
  const { slug, nexttitle, nextslug, prevtitle, prevslug } = pageContext;
  const postNode = data.markdownRemark;
  const post = postNode.frontmatter;
  const date = postNode.fields.date;
  if (!post.id) {
    post.id = slug;
  }
  return (
    <Layout>
      <main>
        <Helmet>
          <title>{`${post.title} | ${config.siteTitle}`}</title>
        </Helmet>
        <Seo postPath={slug} postNode={postNode} postSEO />
        <div>
          <h1>{post.title}</h1>
          <PostMeta>
            {date} &mdash; {postNode.timeToRead} Min Read{" "}
          </PostMeta>
          <PostMeta>
            <PostTags tags={post.tags} />
          </PostMeta>
          <PostContent dangerouslySetInnerHTML={{ __html: postNode.html }} />

          <hr />
          <PostMeta></PostMeta>
        </div>
        <nav>
          <Pagination>
            <li>
              <Link to={prevslug} rel="prev">
                ← {prevtitle}
              </Link>
            </li>
            <li>
              <Link to={nextslug} rel="next">
                {nexttitle}→
              </Link>
            </li>
          </Pagination>
        </nav>
      </main>
    </Layout>
  );
};

export default Post;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover {
          childImageSharp {
            gatsbyImageData(width: 600, layout: CONSTRAINED)
          }
        }
        date
        categories
        tags
      }
      fields {
        slug
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
